import { Util } from '../global/__defaultFunction';
// File#: _1_slider
(function () {
  var Slider = function (element) {
    this.element = element;
    this.rangeWrapper = this.element.getElementsByClassName('slider__range');
    this.rangeInput = this.element.getElementsByClassName('slider__input');
    this.value = this.element.getElementsByClassName('js-slider__value');
    this.floatingValue = this.element.getElementsByClassName('js-slider__value--floating');
    this.rangeMin = this.rangeInput[0].getAttribute('min');
    this.rangeMax = this.rangeInput[0].getAttribute('max');
    this.sliderWidth = window.getComputedStyle(this.element.getElementsByClassName('slider__range')[0]).getPropertyValue('width');
    this.thumbWidth = getComputedStyle(this.element).getPropertyValue('--slide-thumb-size');
    this.isInputVar = (this.value[0].tagName.toLowerCase() == 'input');
    this.isFloatingVar = this.floatingValue.length > 0;
    if (this.isFloatingVar) {
      this.floatingValueLeft = window.getComputedStyle(this.floatingValue[0]).getPropertyValue('left');
    }
    initSlider(this);
  };

  function initSlider(slider) {
    updateLabelValues(slider);// update label/input value so that it is the same as the input range
    updateLabelPosition(slider, false); // update label position if floating variation
    updateRangeColor(slider, false); // update range bg color
    checkRangeSupported(slider); // hide label/input value if input range is not supported

    // listen to change in the input range
    for (var i = 0; i < slider.rangeInput.length; i++) {
      (function (i) {
        slider.rangeInput[i].addEventListener('input', function (event) {
          updateSlider(slider, i);
        });
        slider.rangeInput[i].addEventListener('change', function (event) { // fix issue on IE where input event is not emitted
          updateSlider(slider, i);
        });
      })(i);
    }

    // if there's an input text, listen for changes in value, validate it and then update slider
    if (slider.isInputVar) {
      for (var i = 0; i < slider.value.length; i++) {
        (function (i) {
          slider.value[i].addEventListener('change', function (event) {
            updateRange(slider, i);
          });
        })(i);
      }
    }

    // native <input> element has been updated (e.g., form reset) -> update custom appearance
    slider.element.addEventListener('slider-updated', function (event) {
      for (var i = 0; i < slider.value.length; i++) { updateSlider(slider, i); }
    });

    // custom events - emitted if slider has allows for multi-values
    slider.element.addEventListener('inputRangeLimit', function (event) {
      updateLabelValues(slider);
      updateLabelPosition(slider, event.detail);
    });
  };

  function updateSlider(slider, index) {
    updateLabelValues(slider);
    updateLabelPosition(slider, index);
    updateRangeColor(slider, index);
  };

  function updateLabelValues(slider) {
    for (var i = 0; i < slider.rangeInput.length; i++) {
      slider.isInputVar ? slider.value[i].value = slider.rangeInput[i].value : slider.value[i].textContent = slider.rangeInput[i].value;
    }
  };

  function updateLabelPosition(slider, index) {
    if (!slider.isFloatingVar) return;
    var i = index ? index : 0,
      j = index ? index + 1 : slider.rangeInput.length;
    for (var k = i; k < j; k++) {
      var percentage = (slider.rangeInput[k].value - slider.rangeMin) / (slider.rangeMax - slider.rangeMin);
      slider.floatingValue[k].style.left = 'calc(0.5 * ' + slider.floatingValueLeft + ' + ' + percentage + ' * ( ' + slider.sliderWidth + ' - ' + slider.floatingValueLeft + ' ))';
    }
  };

  function updateRangeColor(slider, index) {
    if (slider.rangeInput.length > 1) { slider.element.dispatchEvent(new CustomEvent('updateRange', { detail: index })); return; }
    var percentage = parseInt((slider.rangeInput[0].value - slider.rangeMin) / (slider.rangeMax - slider.rangeMin) * 100),
      fill = 'calc(' + percentage + '*(' + slider.sliderWidth + ' - 0.5*' + slider.thumbWidth + ')/100)',
      empty = 'calc(' + slider.sliderWidth + ' - ' + percentage + '*(' + slider.sliderWidth + ' - 0.5*' + slider.thumbWidth + ')/100)';

    slider.rangeWrapper[0].style.setProperty('--slider-fill-value', fill);
    slider.rangeWrapper[0].style.setProperty('--slider-empty-value', empty);
  };

  function updateRange(slider, index) {
    var newValue = parseFloat(slider.value[index].value);
    if (isNaN(newValue)) {
      slider.value[index].value = slider.rangeInput[index].value;
      return;
    } else {
      if (newValue < slider.rangeMin) newValue = slider.rangeMin;
      if (newValue > slider.rangeMax) newValue = slider.rangeMax;
      slider.rangeInput[index].value = newValue;
      var inputEvent = new Event('change');
      slider.rangeInput[index].dispatchEvent(inputEvent);
    }
  };

  function checkRangeSupported(slider) {
    var input = document.createElement("input");
    input.setAttribute("type", "range");
    Util.toggleClass(slider.element, 'slider--range-not-supported', input.type !== "range");
  };

  //initialize the Slider objects
  var sliders = document.getElementsByClassName('js-slider');
  if (sliders.length > 0) {
    for (var i = 0; i < sliders.length; i++) {
      (function (i) { new Slider(sliders[i]); })(i);
    }
  }
}());
