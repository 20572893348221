import { Util } from '../global/__defaultFunction';
// File#: _1_image-magnifier
(function () {
  var ImageMagnifier = function (element) {
    this.element = element;
    this.asset = this.element.getElementsByClassName('js-img-mag__asset')[0];
    this.ready = false;
    this.scale = 1;
    this.intervalId = false;
    this.moving = false;
    this.moveEvent = false;
    initImageMagnifier(this);
  };

  function initImageMagnifier(imgMag) {
    // wait for the image to be loaded
    imgMag.asset.addEventListener('load', function () {
      initImageMagnifierMove(imgMag);
    });
    if (imgMag.asset.complete) {
      initImageMagnifierMove(imgMag);
    }
  };

  function initImageMagnifierMove(imgMag) {
    if (imgMag.ready) return;
    imgMag.ready = true;
    initImageMagnifierScale(imgMag); // get image scale
    // listen to mousenter event
    imgMag.element.addEventListener('mouseenter', handleEvent.bind(imgMag));
  };

  function initImageMagnifierScale(imgMag) {
    var customScale = imgMag.element.getAttribute('data-scale');
    if (customScale) { // use custom scale set in HTML
      imgMag.scale = customScale;
    } else { // use natural width of image to get the scale value
      imgMag.scale = imgMag.asset.naturalWidth / imgMag.element.offsetWidth;
      // round to 2 places decimal
      imgMag.scale = Math.floor((imgMag.scale * 100)) / 100;
      if (imgMag.scale > 1.2) imgMag.scale = imgMag.scale - 0.2;
    }
  };

  function initImageMove(imgMag) { // add move event listeners
    imgMag.moveEvent = handleEvent.bind(imgMag);
    imgMag.element.addEventListener('mousemove', imgMag.moveEvent);
    imgMag.element.addEventListener('mouseleave', imgMag.moveEvent);
  };

  function cancelImageMove(imgMag) { // remove move event listeners
    if (imgMag.intervalId) {
      (!window.requestAnimationFrame) ? clearInterval(imgMag.intervalId) : window.cancelAnimationFrame(imgMag.intervalId);
      imgMag.intervalId = false;
    }
    if (imgMag.moveEvent) {
      imgMag.element.removeEventListener('mousemove', imgMag.moveEvent);
      imgMag.element.removeEventListener('mouseleave', imgMag.moveEvent);
      imgMag.moveEvent = false;
    }
  };

  function handleEvent(event) {
    switch (event.type) {
      case 'mouseenter':
        startMove(this, event);
        break;
      case 'mousemove':
        move(this, event);
        break;
      case 'mouseleave':
        endMove(this);
        break;
    }
  };

  function startMove(imgMag, event) {
    imgMag.moving = true;
    initImageMove(imgMag); // listen to mousemove event
    zoomImageMagnifier(imgMag, event);
  };

  function move(imgMag, event) {
    if (!imgMag.moving || imgMag.intervalId) return;
    (!window.requestAnimationFrame)
      ? imgMag.intervalId = setTimeout(function () { zoomImageMagnifier(imgMag, event); }, 250)
      : imgMag.intervalId = window.requestAnimationFrame(function () { zoomImageMagnifier(imgMag, event); });
  };

  function endMove(imgMag) {
    imgMag.moving = false;
    cancelImageMove(imgMag); // remove event listeners
    imgMag.asset.removeAttribute('style'); // reset image style
  };

  function zoomImageMagnifier(imgMag, event) { // zoom effect on mousemove
    var elementRect = imgMag.element.getBoundingClientRect();
    var translateX = (elementRect.left - event.clientX);
    var translateY = (elementRect.top - event.clientY);
    if (translateX > 0) translateX = 0; if (translateX < -1 * elementRect.width) translateX = -1 * elementRect.width;
    if (translateY > 0) translateY = 0; if (translateY < -1 * elementRect.height) translateX = -1 * elementRect.height;
    var transform = 'translateX(' + translateX * (imgMag.scale - 1) + 'px) translateY(' + translateY * (imgMag.scale - 1) + 'px) scale(' + imgMag.scale + ')';
    imgMag.asset.setAttribute('style', 'transform: ' + transform + ';');
    imgMag.intervalId = false;
  };

  // init ImageMagnifier object
  var imageMag = document.getElementsByClassName('js-img-mag');
  if (imageMag.length > 0) {
    for (var i = 0; i < imageMag.length; i++) {
      new ImageMagnifier(imageMag[i]);
    }
  }
}());
