import { Util } from '../global/__defaultFunction';
// File#: _1_pre-header
(function () {
  var preHeader = document.getElementsByClassName('js-pre-header');
  if (preHeader.length > 0) {
    for (var i = 0; i < preHeader.length; i++) {
      (function (i) { addPreHeaderEvent(preHeader[i]); })(i);
    }

    function addPreHeaderEvent(element) {
      var close = element.getElementsByClassName('js-pre-header__close-btn')[0];
      if (close) {
        close.addEventListener('click', function (event) {
          event.preventDefault();
          Util.addClass(element, 'pre-header--is-hidden');
        });
      }
    }
  }
}());
