// File#: _2_points-of-interest
import { Util } from '../global/__defaultFunction';

(function () {
  function initPoi(element) {
    element.addEventListener('click', function (event) {
      var poiItem = event.target.closest('.js-poi__item');
      if (poiItem) Util.addClass(poiItem, 'poi__item--visited');
    });
  };

  var poi = document.getElementsByClassName('js-poi');
  for (var i = 0; i < poi.length; i++) {
    (function (i) { initPoi(poi[i]); })(i);
  }
}());
