import { Util } from '../global/__defaultFunction';
// File#: _1_number-input
(function () {
  var InputNumber = function (element) {
    this.element = element;
    this.input = this.element.getElementsByClassName('js-number-input__value')[0];
    this.min = parseFloat(this.input.getAttribute('min'));
    this.max = parseFloat(this.input.getAttribute('max'));
    this.step = parseFloat(this.input.getAttribute('step'));
    if (isNaN(this.step)) this.step = 1;
    this.precision = getStepPrecision(this.step);
    initInputNumberEvents(this);
  };

  function initInputNumberEvents(input) {
    // listen to the click event on the custom increment buttons
    input.element.addEventListener('click', function (event) {
      var increment = event.target.closest('.js-number-input__btn');
      if (increment) {
        event.preventDefault();
        updateInputNumber(input, increment);
      }
    });

    // when input changes, make sure the new value is acceptable
    input.input.addEventListener('focusout', function (event) {
      var value = parseFloat(input.input.value);
      if (value < input.min) value = input.min;
      if (value > input.max) value = input.max;
      // check value is multiple of step
      value = checkIsMultipleStep(input, value);
      if (value != parseFloat(input.input.value)) input.input.value = value;

    });
  };

  function getStepPrecision(step) {
    // if step is a floating number, return its precision
    return (step.toString().length - Math.floor(step).toString().length - 1);
  };

  function updateInputNumber(input, btn) {
    var value = (Util.hasClass(btn, 'number-input__btn--plus')) ? parseFloat(input.input.value) + input.step : parseFloat(input.input.value) - input.step;
    if (input.precision > 0) value = value.toFixed(input.precision);
    if (value < input.min) value = input.min;
    if (value > input.max) value = input.max;
    input.input.value = value;
    input.input.dispatchEvent(new CustomEvent('change', { bubbles: true })); // trigger change event
  };

  function checkIsMultipleStep(input, value) {
    // check if the number inserted is a multiple of the step value
    var remain = (value * 10 * input.precision) % (input.step * 10 * input.precision);
    if (remain != 0) value = value - remain;
    if (input.precision > 0) value = value.toFixed(input.precision);
    return value;
  };

  //initialize the InputNumber objects
  var inputNumbers = document.getElementsByClassName('js-number-input');
  if (inputNumbers.length > 0) {
    for (var i = 0; i < inputNumbers.length; i++) {
      (function (i) { new InputNumber(inputNumbers[i]); })(i);
    }
  }
}());
