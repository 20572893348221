/**
 * The Sew Shack - Custom JS
 */
import './global/__classManipulations';
// import { Util } from './global/__defaultFunction';
/* Animation curves */
import './global/__animationsCurves.js';

/* import Components */
import './components/_1_accordion';
import './components/_1_adaptive-navigation';
import './components/_1_animated-headline';
import './components/_1_choice-accordion';
import './components/_1_choice-buttons';
import './components/_1_choice-images';
import './components/_1_col-table';
import './components/_1_color-swatches';
import './components/_1_countdown';
import './components/_1_custom-select';
import './components/_1_details';
import './components/_1_diagonal-movement';
import './components/_1_filter';
import './components/_1_form-validator';
import './components/_1_google-maps';
import './components/_1_image-magnifier';
import './components/_1_lazy-load';
import './components/_1_menu';
import './components/_1_modal-window';
import './components/_1_number-input';
import './components/_1_overscroll-section';
import './components/_1_pre-header';
import './components/_1_rating';
import './components/_1_read-more';
import './components/_1_responsive-sidebar';
import './components/_1_reveal-effects';
import './components/_1_scrolling-animations';
import './components/_1_side-navigation-v2';
import './components/_1_slider';
import './components/_1_smooth-scrolling';
import './components/_1_social-sharing';
import './components/_1_sticky-hero';
import './components/_1_swipe-content';
import './components/_1_table';
import './components/_1_tabs';
import './components/_1_tooltip';
import './components/_2_carousel';
import './components/_2_checkout';
import './components/_2_comments';
import './components/_2_dropdown';
import './components/_2_menu-bar';
import './components/_2_points-of-interest';
import './components/_2_product-v3';
import './components/_2_slider-multi-value';
import './components/_2_slideshow';
import './components/_2_sticky-sharebar';
import './components/_2_table-of-contents';
import './components/_3_advanced-filter';
import './components/_3_hiding-nav';
import './components/_3_looping-slideshow';
import './components/_3_mega-site-navigation';
import './components/_3_thumbnail-slideshow';
if (window.location.href.indexOf('customizer') > -1) {
  import('./components/_3_wizard-form');
};


