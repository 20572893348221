// File#: _2_slider-multi-value
import { Util } from '../global/__defaultFunction';
(function () {
  var SliderMulti = function (element) {
    this.element = element;
    this.rangeWrapper = this.element.getElementsByClassName('slider__range');
    this.rangeInput = this.element.getElementsByClassName('slider__input');
    this.rangeMin = this.rangeInput[0].getAttribute('min');
    this.rangeMax = this.rangeInput[0].getAttribute('max');
    this.sliderWidth = window.getComputedStyle(this.element.getElementsByClassName('slider__range')[0]).getPropertyValue('width');
    this.thumbWidth = getComputedStyle(this.element).getPropertyValue('--slide-thumb-size');
    initSliderMulti(this);
  };

  function initSliderMulti(slider) {
    // toggle custom class based on browser support
    toggleMsClass(slider);

    // init bg color of the slider
    updateRangeColor(slider);

    slider.element.addEventListener('updateRange', function (event) {
      checkRangeValues(slider, event.detail);
      updateRangeColor(slider);
    });

    // custom event emitted after window resize
    slider.element.addEventListener('update-slider-multi-value', function (event) {
      slider.sliderWidth = window.getComputedStyle(slider.element.getElementsByClassName('slider__range')[0]).getPropertyValue('width');
      updateRangeColor(slider);
    });
  };

  function checkRangeValues(slider, index) {
    // if min value was changed -> make sure min value is smaller than max value
    // if max value was changed -> make sure max value is bigger than min value
    var i = (index == 0) ? 1 : 0,
      limit = parseFloat(slider.rangeInput[i].value);
    if ((index == 0 && slider.rangeInput[0].value >= limit) || (index == 1 && slider.rangeInput[1].value <= limit)) {
      slider.rangeInput[index].value = limit;
      slider.element.dispatchEvent(new CustomEvent('inputRangeLimit', { detail: index }));
    }
  };

  function updateRangeColor(slider) { // update background fill color of the slider
    var percentageStart = parseInt((slider.rangeInput[0].value - slider.rangeMin) / (slider.rangeMax - slider.rangeMin) * 100),
      percentageEnd = parseInt((slider.rangeInput[1].value - slider.rangeMin) / (slider.rangeMax - slider.rangeMin) * 100),
      start = 'calc(' + percentageStart + '*(' + slider.sliderWidth + ' - 0.5*' + slider.thumbWidth + ')/100)',
      end = 'calc(' + percentageEnd + '*(' + slider.sliderWidth + ' - 0.5*' + slider.thumbWidth + ')/100)';

    slider.rangeWrapper[0].style.setProperty('--slider-fill-value-start', start);
    slider.rangeWrapper[0].style.setProperty('--slider-fill-value-end', end);
  };

  function toggleMsClass(slider) {
    var cssVariablesSupport = Util.cssSupports('--color-value', 'red'),
      imeAlignSuport = Util.cssSupports('-ms-ime-align', 'auto');
    if (imeAlignSuport || !cssVariablesSupport) Util.addClass(slider.element, 'slider--ms-fallback'); // IE and Edge (<=18) Fallback
  };

  //initialize the SliderMulti objects
  var slidersMulti = document.getElementsByClassName('js-slider');
  if (slidersMulti.length > 0) {
    var slidersMultiArray = [];
    for (var i = 0; i < slidersMulti.length; i++) {
      (function (i) {
        if (slidersMulti[i].getElementsByClassName('slider__input').length > 1) slidersMultiArray.push(new SliderMulti(slidersMulti[i]));
      })(i);
    }
    if (slidersMultiArray.length > 0) {
      var resizingId = false,
        customEvent = new CustomEvent('update-slider-multi-value');

      window.addEventListener('resize', function () {
        clearTimeout(resizingId);
        resizingId = setTimeout(doneResizing, 500);
      });

      function doneResizing() {
        for (var i = 0; i < slidersMultiArray.length; i++) {
          (function (i) { slidersMultiArray[i].element.dispatchEvent(customEvent); })(i);
        };
      };
    }
  }
}());
