import { Util } from '../global/__defaultFunction';
// File#: _1_social-sharing
(function () {
  function initSocialShare(button) {
    button.addEventListener('click', function (event) {
      event.preventDefault();
      var social = button.getAttribute('data-social');
      var url = getSocialUrl(button, social);
      (social == 'mail')
        ? window.location.href = url
        : window.open(url, social + '-share-dialog', 'width=626,height=436');
    });
  };

  function getSocialUrl(button, social) {
    var params = getSocialParams(social);
    var newUrl = '';
    for (var i = 0; i < params.length; i++) {
      var paramValue = button.getAttribute('data-' + params[i]);
      if (params[i] == 'hashtags') paramValue = encodeURI(paramValue.replace(/\#| /g, ''));
      if (paramValue) {
        (social == 'facebook')
          ? newUrl = newUrl + 'u=' + encodeURIComponent(paramValue) + '&'
          : newUrl = newUrl + params[i] + '=' + encodeURIComponent(paramValue) + '&';
      }
    }
    if (social == 'linkedin') newUrl = 'mini=true&' + newUrl;
    return button.getAttribute('href') + '?' + newUrl;
  };

  function getSocialParams(social) {
    var params = [];
    switch (social) {
      case 'twitter':
        params = ['text', 'hashtags'];
        break;
      case 'facebook':
      case 'linkedin':
        params = ['url'];
        break;
      case 'pinterest':
        params = ['url', 'media', 'description'];
        break;
      case 'mail':
        params = ['subject', 'body'];
        break;
    }
    return params;
  };

  var socialShare = document.getElementsByClassName('js-social-share');
  if (socialShare.length > 0) {
    for (var i = 0; i < socialShare.length; i++) {
      (function (i) { initSocialShare(socialShare[i]); })(i);
    }
  }
}());
